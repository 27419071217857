import { type ClientLoaderFunctionArgs, redirect } from '@remix-run/react';

export function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);
  url.pathname = url.pathname.replace('/tags', '/home/tags');

  return redirect(url.toString());
}

export default function Component(): JSX.Element {
  return <></>;
}

export const HydrateFallback = () => <></>;
